import { Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useCallback, useMemo } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import dinero from 'dinero.js';
import styled from '@emotion/styled';
import type { CSSProperties } from 'react';
import type { SeatType } from '@shared/schema/src';

export enum AddOrSubtractMode {
  ADD = 'add',
  SUBTRACT = 'subtract'
}

const ContentContainer = styled.div `
  display: grid;
  gap: 12px;  
`;

const LabelContainer = styled.div`
  display: grid;
  gap: 5px;
`;

const Title = styled.div `
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-word;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0px;
  line-height: 1.2;
`;

const SubTitle = styled.div`
  font-size: 12px;
`;

const CustomIconButton = styled(motion(IconButton))`
  padding: 0;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 100px;
  justify-content: space-between;
  align-items: center;
`;

const Count = styled(Typography)(({
  theme,
}) => `
  margin: ${theme.spacing(0, 1)};
`);

const tapStyle = {
  scale: 0.9,
};
const hoverStyle = {
  scale: 1.1,
};
const disabledStyle: CSSProperties = {
  opacity: 0.2,
};
const defaultStyle: CSSProperties = {
  opacity: 1,
};

interface AddOrSubtractProps {
  price?: number;
  count: number,
  min?: number;
  max: number;
  type: SeatType;
  billingPeriod: 'monthly' | 'annually';
  role: 'users' | 'facilitators';
  onAdd?: (role: 'users' | 'facilitators') => void;
  onRemove?: (role: 'users' | 'facilitators') => void;
}

const AddOrSubtract = ({
  count = 1,
  min = 100,
  max,
  price = 2000,
  billingPeriod,
  role,
  type,
  onAdd,
  onRemove,
}: AddOrSubtractProps) => {
  const {
    subDisabled,
    addDisabled,
  } = useMemo(() => {
    return {
      subDisabled: count <= min,
      addDisabled: count >= max,
    };
  }, [min, count, max,]);

  const priceText = useMemo(() => {
    if (count === min) {
      return 'Included in price';
    }
    const divisor = role === 'users' ? 10 : 1;
    return `+${dinero({
      amount: price * (count - min) / divisor,
      currency: 'EUR',
    }).setLocale('fi-Fi')
      .toFormat('$0,0.00')} / ${billingPeriod === 'monthly' ? 'month' : 'year'}`;
  }, [billingPeriod, count, min, price, role,]);

  const handleAdd = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onAdd?.(role);
  }, [onAdd, role,]);

  const handleRemove = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onRemove?.(role);
  }, [onRemove, role,]);

  return <ContentContainer>
    <LabelContainer>
      <Title>{type}</Title>
      <SubTitle>
        {priceText ? priceText : ''}
      </SubTitle>
    </LabelContainer>

    {/* Add and subtract action icons */}
    <ButtonContainer>
      <CustomIconButton
        onClick={handleRemove}
        disabled={subDisabled}
        whileTap={tapStyle}
        whileHover={hoverStyle}
        style={
          subDisabled ? disabledStyle : defaultStyle
        }
        value={count}>
        <RemoveCircleIcon color='primary'/>
      </CustomIconButton>

      <Count variant='h6'>
        {count}
      </Count>

      <CustomIconButton
        onClick={handleAdd}
        disabled={addDisabled}
        style={{
          opacity: addDisabled ? 0.2 : 1,
        }}
        value={count}>
        <AddCircleIcon color='primary'/>
      </CustomIconButton>
    </ButtonContainer>
    {/* <SubTitle>Current plan: {maxCount}</SubTitle> */}
  </ContentContainer>;
};

export default AddOrSubtract;
