import { ADMIN_BUNDLE } from '@shared/schema/src/https/bundles';
import { createBundleFunctionFactory } from 'utils/firebase/bundles/createBundleFunctionFactory';
import type { CreateSpaceMessage, UpdateSpaceMessage, UpdateSpaceSettingsMessage } from '@shared/schema/src';

const factory = createBundleFunctionFactory(ADMIN_BUNDLE);

export const createSpace = factory<CreateSpaceMessage>(
  'create-space'
);

export const updateSpace = factory<UpdateSpaceMessage>(
  'update-space'
);

export const updateSpaceSettings = factory<UpdateSpaceSettingsMessage>(
  'update-space-settings'
);
