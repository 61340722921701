import { Typography } from '@mui/material';
import Button from 'components/common/Button';
import styled from '@emotion/styled';
import theme from 'theme';

interface LandingPreviewProps {
  header? : string;
  title : string;
  text : string;
  imageUrl : string;
  rightImage? : boolean;
  buttonText? : string;
}

const ContentArea = styled.div<{
  $rightImage?: boolean;
}>(({
  $rightImage,
}) => `
  ${theme.breakpoints.down('md')} {
    flex-direction: column;
  }
  display: flex;
  flex-direction: ${$rightImage ? 'row-reverse' : 'row'};
`);

const HeaderArea = styled.div`
  width: 100%;
  display: flex;
`;

const Header = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #0086A4;
`;

const TextArea = styled.div<{
  $rightImage?: boolean;
  }>(({
    $rightImage,
  }) => `
  ${theme.breakpoints.down('lg')} {
    width: 100%;
    min-height: fit-content;
    gap: ${theme.spacing(3)};
    padding: ${theme.spacing(2)};
  }
  width: 50%;
  min-height: 660px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${$rightImage ? theme.spacing(1, 5, 2, 0) : theme.spacing(1, 0, 2, 5)};
  gap: ${theme.spacing(5)};
`);

const Title = styled(Typography)`
  ${theme.breakpoints.between('md', 'lg')} {
    font-size: 3rem;
    line-height: 3rem;
  }
  ${theme.breakpoints.down('md')} {
    font-size: 2.1rem;
    line-height: 3rem;
  }
  font-size: 4rem;
  font-weight: 600;
  line-height: 5rem;
`;

const Text = styled(Typography)`
  font-size: 1.25rem;
  line-height: 2rem;
`;

const ImageArea = styled.div`
  ${theme.breakpoints.down('lg')} {
    width: 100%;
    min-height: fit-content;
  }
  width: 50%;
  min-height: 660px;
  max-height: 660px;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const LandingSecondaryHero = ({
  header,
  title,
  text,
  imageUrl,
  rightImage,
  buttonText,
}: LandingPreviewProps) => {
  return <ContentArea
    $rightImage={rightImage}>

    <ImageArea>
      <Image src={imageUrl}/>
    </ImageArea>
    <TextArea $rightImage={rightImage}>
      {
        header &&
          <HeaderArea>
            <Header>
              {header}
            </Header>
          </HeaderArea>
      }
      <Title>
        {title}
      </Title>
      <Text>
        {text}
      </Text>
      {
        buttonText &&
          <Button
            withoutMargin
            variant='contained'
          >
            {buttonText}
          </Button>
      }

    </TextArea>

  </ContentArea>;
};
export default LandingSecondaryHero;
