/* eslint-disable max-len */
import {
  Card, CardActions, CardContent, Chip, Typography
} from '@mui/material';
import { parseTextEmphasis } from '../../parseMentionsAndHasTagsToComponents';
import { useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import ArrowForward from '@mui/icons-material/ArrowForward';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';
import theme from 'theme';
import type { CSSProperties } from 'react';

interface LandingCardProps {
  title?: string;
  text?: string;
  imageSrc?: string;
  featured?: boolean;
  label?: boolean;
  arrow?: boolean;
}

const StyledCard = styled$(Card)<{
  $featured?: boolean;
}>(({
  theme,
  $featured,
}) => `
  width: 300px;
  height: 200px;
  border-radius: ${theme.shape.borderRadius}px;
  background-color: ${$featured ? '#0086A4' : theme.palette.background.innerPaper};
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 5px 5px 5px #000000;
`);

const Image = styled.img`
  width: 48px;
  height: 48px;
  object-fit: cover;
`;

const StyledArrowForward = styled$(ArrowForward)<{
  $featured?: boolean;
}>(({
  $featured,
}) => `
  width: 48px;
  height: 48px;
  color: ${$featured ? theme.palette.primary.contrastText : '#000'};
`);

const LabelContainer = styled.div`
  display: flex;
  margin-top: ${theme.spacing(2)};
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledChip = styled(Chip)`
  min-height: 22px;
  margin-right: 6px;
  margin-bottom: 10px;
  color: ${theme.palette.primary.main};
  background-color: ${theme.palette.background.innerPaper}};
  border: 1px solid #5bc2fa;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  max-width: 200px;
  min-width: 50px;
  font-weight: 400;
`;

export const Emphasis = styled.span`
  color: ${theme.palette.primary.main};
`;

const ArrowContainer = styled(CardActions)`
  padding-top: 0;
`;

const LandingCard = ({
  title,
  text,
  imageSrc,
  featured,
  label,
  arrow,
}: LandingCardProps) => {
  const {
    t,
  } = useTranslation();

  const typographyStyle = useMemo((): CSSProperties => {
    return {
      marginBottom: arrow ? 0 : '16px',
    };
  }, [arrow,]);

  const compiledText = useMemo(() => {
    if (!text) {
      return '';
    }
    return parseTextEmphasis(text);
  }, [text,]);

  return <>
    <StyledCard $featured={featured}>
      <CardContent sx={{
        position: 'relative',
      }}>
        {imageSrc ? <Image src={imageSrc} /> : null}
        {
          title && <Typography
            variant="body1"
            fontWeight={600}
            textAlign="center"
            color={featured ? theme.palette.primary.contrastText : '#000'}
          >
            {t(title)}
          </Typography>
        }
        {label && text ?
          <LabelContainer>
            {
              text.split(' ').map((label, index) => {
                return <StyledChip
                  key={`${label }_${ index}`}
                  label={label}
                  variant={'outlined'}
                  color={'primary'}
                />;
              })
            }
          </LabelContainer> :
          compiledText && <Typography
            sx={typographyStyle}
            variant="body1"
            textAlign={'center'}
            color={featured ? theme.palette.primary.contrastText : '#000'}>
            {compiledText}
          </Typography>
        }
      </CardContent>
      {arrow ?
        <ArrowContainer >
          <StyledArrowForward $featured={featured} />
        </ArrowContainer> : null
      }
    </StyledCard>
  </>;
};

export default LandingCard;
