import type { DocumentSnapshot } from 'firebase/firestore';
import type { WithId } from '@mindhiveoy/schema';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapDocument = <T = any>(doc: DocumentSnapshot<T>): WithId<T> => {
  return doc && doc.exists() && doc.data() ? {
    ...doc.data(),
    _id: doc.id,
  } as WithId<T> : null as unknown as WithId<T>;
};
