import { UserHomeView } from './UserHomeView';
import { getVariantDisplayName } from 'utils/variantUtils';
import { useAuth } from '@mindhiveoy/react-auth';
import DefaultScreenLayout from '../layout/DefaultScreenLayout';
import Head from 'next/head';
import LandingPageComponent from 'components/landing/LandingPageComponent';
import React, { useMemo } from 'react';

/**
 * Home content is the root page of the application.
 *
 * It will show the landing page if the user is not logged in,
 * otherwise it will show the user home view. It also shows
 * the indication if the data for the page is being loaded.
 *
 * @param props
 * @returns
 */
export const HomeContent = () => {
  const displayName = useMemo(() => getVariantDisplayName(), []);

  const {
    authenticated,
  } = useAuth();

  return <>
    <Head>
      <title>{displayName}</title>
    </Head>
    <DefaultScreenLayout>
      {authenticated ?
        <UserHomeView /> :
        <LandingPageComponent />
      }
    </DefaultScreenLayout>
  </>;
};
