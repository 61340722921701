import { useAuthentication } from 'components/auth/AuthProvider';
import { useRouter } from 'next/router';
import { useSpaces } from 'bloc/space/SpacesBloc';
import { useTranslation } from 'next-i18next';
import ChangePlanDialog from 'components/billingAndInvoices/components/dialogs/ChangePlanDialog';
import Grid from '@mui/material/Grid';
import LoadingIndicator from 'components/common/LoadingIndicator';
import PlanSelector from '.';
import React, { useCallback, useState } from 'react';
import SectionTitle from '../../layout/SectionTitle';
import styled from '@emotion/styled';
import type { PlanId } from '../../../@shared/schema/src';

export const StyledGrid = styled(Grid)(({
  theme,
}) => `
  margin-left: auto;
  margin-right: auto;
  padding: ${theme.spacing(4, 2, 2, 2)};
  max-width: 900px;
`);

export const PlanContainer = styled.div`
  margin-top: 8vh;
`;

export const PlanSelectorContainer = () => {
  return <PlanSelectorImpl />;
};

const PlanSelectorImpl = () => {
  const {
    user,
    openSignUpDialog,
  } = useAuthentication();

  const {
    t,
  } = useTranslation();

  const [creatingSpace, setCreatingSpace,] = useState(false);
  const [dialogOpen, setDialogOpen,] = useState(false);
  const [selectedPlan, setSelectedPlan,] = useState<PlanId>();

  const spacesBloc = useSpaces();

  const {
    push,
  } = useRouter();

  const handleSwitchSubscription = useCallback(
    async (plan: PlanId) => {
      setSelectedPlan(plan);

      if (user?.isAnonymous) {
        openSignUpDialog(plan);
        return;
      }

      if (plan === 'free') {
        // To start a free plan, we need to create a space
        setCreatingSpace(true);
        const space = await spacesBloc.create(plan);
        const path = `/${space._id}/`;
        push(path, path, {
          shallow: true,
        });
        return;
      }
      if (!user?.uid) {
        return;
      }

      setDialogOpen(true);
    },
    [openSignUpDialog, push, spacesBloc, user?.isAnonymous, user?.uid,]
  );

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  return !user?.plan || user?.plan === 'free' ? <>
    <PlanContainer>
      <SectionTitle>{t('create-your-own-space')}
      </SectionTitle>
      <StyledGrid
        container>
        {
          creatingSpace ? <LoadingIndicator loaderTitle={t('creating-your-own-space')} /> :
            <PlanSelector
              currentPlan={user?.plan}
              onSwitchPlan={handleSwitchSubscription} />
        }
      </StyledGrid>
    </PlanContainer>

    {dialogOpen ? <ChangePlanDialog
      mode={'upgrade'}
      open={dialogOpen}
      planId={selectedPlan}
      onClose={handleCloseDialog}
    /> : null
    }
  </> : null;
};
