import AavistusLandingPageComponent from './variants/aavistus/LandingPageComponent';
import XdelphiLandingPageComponent from './variants/xdelphi/LandingPageComponent';
import config from 'config';

const LandingPageComponent = () => {
  switch (config.environment.variant) {
    case 'aavistus':
      return <AavistusLandingPageComponent />;
    case 'xdelphi':
      return <XdelphiLandingPageComponent />;
    default:
      return <AavistusLandingPageComponent />;
  }
};

export default LandingPageComponent;
