
import { createUseBlocHook } from '@mindhiveoy/react-bloc/createUseBlocDocumentHook';
import { updateSpace } from './spaceApi';
import { validateSessionProps } from '@shared/schema/src';
import BlocDocument from '@mindhiveoy/bloc/BlocDocument';
import elementCache from 'utils/ElementCache';
import type { BlocErrorFunction } from '@mindhiveoy/bloc/types';
import type { Space, SpaceParams } from '@shared/schema/src';

/**
 * Single Space document business logic
 */
export class SpaceBloc extends BlocDocument<Space, SpaceParams> {
  /**
   * @param {SpaceParams} params Space params
   * @param {ErrorFunction} onError Error listener
   */
  constructor(
    params: SpaceParams,
    onError?: BlocErrorFunction
  ) {
    const {
      spaceId,
    } = params;
    validateSessionProps(params, 'spaceId');
    const documentPath =
      `spaces/${spaceId}`;

    super({
      documentPath,
      params,
      onError,
    });

    this.registerUpdateListener((data) => {
      if (!data) {
        return;
      }
      elementCache.set({
        keyPath: {
          spaceId,
        },
        data,
      });
    });
  }
  /**
   * Update space.
   *
   * @param {Space} space
   * @return {Promise<Space>}}  return's immediately optimistic update of the space. The space content
   *                            change will trigger after backend update if significant changes appears.
   */
  public update = async (space: Partial<Space>, deleted: string[]) => {
    return this._set(
      async () => {
        return await updateSpace({
          ...this.params,
          space,
          deleted,
        });
      },
      this.onError,
      (data) => data && {
        ...data,
        ...space,
        _id: this.docId,
      }
    );
  };
}

export const useSpace = createUseBlocHook<Space, SpaceParams, SpaceBloc>(
  ({
    params,
    onError,
  }) =>
    new SpaceBloc(
      params,
      onError
    )
);
