import { HomeContent } from './HomeContent';
import { useAuth } from '@mindhiveoy/react-auth';
import App from 'components/App';
import React from 'react';

export const HomeScreenView = () => {
  const {
    authenticated,
  } = useAuth();

  return <App noBackground={!authenticated}>
    <HomeContent />
  </App>;
};
