
import { HomeScreenView } from 'components/home/HomeScreenView';
import { getVariantDisplayName } from 'utils/variantUtils';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import React from 'react';

const displayName = getVariantDisplayName();

/**
 * The front page of the whole application.
 */
const Index = () => {
  // TODO Meta information from active profile

  return <>
    <Head>
      <title>{displayName}</title>
    </Head>
    <HomeScreenView />
  </>;
};

export const getStaticProps = async ({
  locale,
}: any) => {
  const localeData = await serverSideTranslations(
    locale,
    ['common', 'dashboard',]
  );

  return {
    props: {
      ...localeData,
    },
  };
};

export default Index;
