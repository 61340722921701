/* eslint-disable @typescript-eslint/no-explicit-any */
import { BlocQuery } from '../../@mindhiveoy/bloc/BlocQuery';
import { createUseBlocHook } from '../../@mindhiveoy/react-bloc/createUseBlocDocumentHook';
import type { PlanId, Space, SpaceId } from '../../@shared/schema/src';
import type { SpaceParams } from '../../@shared/schema/src/pathParams';

import { createSpace } from './spaceApi';
import type { BlocErrorFunction } from '../../@mindhiveoy/bloc/types';

/**
 *
 */
export class SpacesBloc extends BlocQuery<Space, any> {
  /**
   * @param {ErrorFunction} onError Error listener
   */
  constructor(
    onError?: BlocErrorFunction
  ) {
    super({
      collectionPath: 'spaces',
      params: {},
      onError,
    });
  }

  /**
   * Create a new space.
   * @param {PlanId} planId
   * @param {SpaceId} spaceId
   * @param {string} creatorName
   * @return {Promise<WithId<Space>>}
   */
  public create = async (
    planId: PlanId,
    spaceId?: SpaceId,
    creatorName?: string
  ) => {
    return this._create(
      async () => {
        return await createSpace({
          creatorName,
          planId,
          spaceId,
        });
      },
      this.onError
    );
  };
}

export const useSpaces =
  createUseBlocHook<Space, SpaceParams, SpacesBloc>(
    ({
      onError,
    }) => new SpacesBloc(onError)
  );
