/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable sonarjs/no-duplicate-string */
import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useAuthentication } from 'components/auth/AuthProvider';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'next-i18next';
import AavistusLandingNavbar from './components/AavistusLandingNavbar';
import CardStrip from 'components/cards/CardStrip';
import CookieFAB from 'components/cookies/CookieFAB';
import LandingCard from './components/LandingCard';
import LandingHero from './components/LandingHero';
import LandingSecondaryHero from './components/LandingSecondaryHero';
import PlanSelector from 'components/plans/PlanSelector';
import SectionTitle from 'components/layout/SectionTitle';
import styled from '@emotion/styled';
import theme from 'theme';
import type { PlanId } from '@shared/schema/src';

const imageData = [
  {
    _id: 'Image1',
    media: {
      photoURL: '/images/landingpage/carousel/1.png',
      tags: [],
      type: 'photo',
    },
  },
  {
    _id: 'Image2',
    media: {
      photoURL: '/images/landingpage/carousel/2.png',
      tags: [],
      type: 'photo',
    },
  },
  {
    _id: 'Image3',
    media: {
      photoURL: '/images/landingpage/carousel/3.png',

      tags: [],
      type: 'photo',
    },
  },
  {
    _id: 'Image4',
    media: {
      photoURL: '/images/landingpage/carousel/4.png',

      tags: [],
      type: 'photo',
    },
  },
  {
    _id: 'Image5',
    media: {
      photoURL: '/images/landingpage/carousel/5.png',
      tags: [],
      type: 'photo',
    },
  },
  {
    _id: 'Image6',
    media: {
      photoURL: '/images/landingpage/carousel/6.png',
      tags: [],
      type: 'photo',
    },
  },
];

const PreviewContainer = styled.div`
  ${theme.breakpoints.between('md', 'lg')} {
    padding: ${theme.spacing(6, 4)};
  }
  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(6, 2)};
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${theme.spacing(10, 20)};
  gap: ${theme.spacing(6)};
`;

const CardContainer = styled.div`
  ${theme.breakpoints.down('lg')} {
    padding: ${theme.spacing(4)};
  }
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(4)}; 
  padding: ${theme.spacing(10, 20)};
`;

const CarouselContainer = styled.div`
  ${theme.breakpoints.down('lg')} {
    padding: ${theme.spacing(4, 0)};
  }
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: ${theme.spacing(6)};
  padding-bottom: ${theme.spacing(18)};
`;

// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
const CardHeader = styled(Typography)`
  ${theme.breakpoints.down('sm')} {
    font-size: 1.4rem;
  }
  font-size: 1.6rem;
  font-weight: 500;
`;

const Cards = styled.div`
  ${theme.breakpoints.down('lg')} {
    flex-direction: column;
    gap: ${theme.spacing(3)}; 
  }
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(5)}; 
`;

// const PlanContainer = styled.div(({
//   theme,
// }) => `
//   margin: ${theme.spacing(10, 2)};
// `);

// const StyledGrid = styled(Grid)(({
//   theme,
// }) => `
//   margin-left: auto;
//   margin-right: auto;
//   padding: ${theme.spacing(4, 2, 2, 2)};
//   max-width: min(100vw, 800px);
//   box-sizing: border-box;
//   position: relative;
// `);

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${theme.palette.background.graph};
`;

const CarouselTitle = styled(Typography)(({
  theme,
}) => `
  text-align: center;
  line-height: 2.5rem;
  padding: ${theme.spacing(0, 4)};
`);

// eslint-disable-next-line unused-imports/no-unused-vars, @typescript-eslint/no-unused-vars
const FeaturedText = styled.span`
  color: ${theme.palette.primary.main};
`;

const Footer = styled.div`
  ${theme.breakpoints.down('md')} {
    margin: ${theme.spacing(3, 2)};
  }
  display: flex;
  flex-direction: column;
  margin: ${theme.spacing(3, 20)};
  align-items: center;
`;

const AavistusLandingPageComponent = () => {
  const {
    t,
  } = useTranslation();

  const {
    openSignUpDialog,
  } = useAuthentication();

  const handleSubscribe = useCallback((planId: PlanId) => {
    openSignUpDialog(planId);
  }, [openSignUpDialog,]);

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletOrLaptop = useMediaQuery(theme.breakpoints.between('sm', 'lg'));

  const cardStyles = useMemo(() => {
    if (isMobile) {
      return {
        CARD_WIDTH: 295,
        CARD_HEIGHT: 210,
        ARROW_SIZE: 60,
        ARROW_HEIGHT: 365,
      };
    }
    if (isTabletOrLaptop) {
      return {
        CARD_WIDTH: 360,
        CARD_HEIGHT: 275,
        ARROW_SIZE: 90,
        ARROW_HEIGHT: 470,
      };
    }

    return {
      CARD_WIDTH: 500,
      CARD_HEIGHT: 415,
      ARROW_SIZE: 110,
      ARROW_HEIGHT: 580,
    };
  }, [isMobile, isTabletOrLaptop,]);

  const firstSetOfHeroes = useMemo(() => [
    {
      id: 'hero1',
      title: t('aavistus-hero-1-title'),
      text: t('aavistus-hero-1-text'),
      imageUrl: '/images/landingpage/hr1.png',
      buttonText: '',
      rightImage: true,
    },
    {
      id: 'hero2',
      title: t('aavistus-hero-2-title'),
      text: t('aavistus-hero-2-text'),
      imageUrl: '/images/landingpage/hr2.png',
      buttonText: '',
    },
    {
      id: 'hero3',
      header: t('aavistus-hero-3-header'),
      title: t('aavistus-hero-3-title'),
      text: t('aavistus-hero-3-text'),
      imageUrl: '/images/landingpage/hr3.png',
      rightImage: true,
    },
  ], [t,]);

  const featurePickCards = useMemo(() => [
    {
      id: 'card1',
      label: true,
      text: t('Use labels to easily organize your discussions and learn from data'),
    },
    {
      id: 'card2',
      text: t('facilitated-processes-are-_anonymous-as-default-but-you-can-still-mention-different-users-because-of-their-alter-ego-names'),
    },
    {
      id: 'card3',
      title: t('aavistus-card-3-title'),
      text: t('aavistus-card-3-text'),
      featured: true,
      arrow: true,
    },
  ], [t,]);

  return <>
    <AavistusLandingNavbar />
    <LandingHero />

    {/* App presentation w/ secondary heroes */}
    <PreviewContainer>
      {firstSetOfHeroes.map((hero: any) => {
        return <Grid item
          xs='auto'
          key={hero.id}
        >
          <LandingSecondaryHero
            title={t(`${hero.title}`)}
            text={t(`${hero.text}`)}
            imageUrl={hero.imageUrl}
            buttonText={hero.buttonText}
            rightImage={hero.rightImage}
          />
        </Grid>;
      })}
    </PreviewContainer>

    {/* Features presentation w/ small cards */}
    <CardContainer id='features'>
      <SectionTitle>{t('Always-growing set of features for real-time collaboration')}</SectionTitle>
      <Cards>
        {featurePickCards.map((post) => {
          return <Grid item
            xs='auto'
            key={post.id}
          >
            <LandingCard
              title={post.title}
              text={post.text}
              featured={post.featured}
              label={post.label}
              arrow={post.arrow}
            />
          </Grid>;
        })}
      </Cards>
    </CardContainer>

    {/* Carousel */}
    <CarouselContainer>
      {/* <SectionTitle>{t('Manage all your projects with ease')}</SectionTitle> */}
      <CarouselTitle variant="h3">
        {t('Start your experiencing Aavistus to find out what it can do for your collaboration')}.
      </CarouselTitle>
      <CardStrip
        menu={false}
        centerOnDefault
        cards={imageData as any}
        reflection
        cardWidth={cardStyles.CARD_WIDTH}
        cardHeight={cardStyles.CARD_HEIGHT}
        arrowSize={cardStyles.ARROW_SIZE}
        arrowHeight={cardStyles.ARROW_HEIGHT}
      />
    </CarouselContainer>

    {/* Plans */}
    {/* <PlanContainer id='pricing'> */}
    <SectionTitle>{t('Create your own space and start learning')}</SectionTitle>

    <div
      id='pricing'
      style={{
        paddingTop: '2rem',
        maxWidth: 'min(1000px, 100%)',
        margin: 'auto',
        position: 'relative',
      }}>
      <PlanSelector onSwitchPlan={handleSubscribe} />
    </div>

    {/* TODO: enable these later */}
    {/* Blog cards */}
    {/*     <CardContainer style={{
      backgroundColor: '#101018',
    }}>
      <CardHeader>
        {t('From our blog')}
      </CardHeader>
      <Typography variant="h5">
        Lorem ipsum dolor sit amet
      </Typography>
      <Cards>
        {mockBlogData.map((post) => {
          return <Grid item
            xs='auto'
            key={post.id}
          >
            <LandingBlogCard
              title={post.title}
              imageSrc={post.imageSrc}
              author={post.author}
            />
          </Grid>;
        })}
      </Cards>
      <Button
        withoutMargin
        variant='contained'>
        {t('View all posts')}
      </Button>
    </CardContainer> */}

    <AavistusLandingNavbar bottomNav />

    <Divider />

    {/* Footer */}
    <Footer>
      <Typography textAlign='center'>
        {t('aavistus-platform-is-served-to-you-in-co-operation-by-mindhive-oy-and-metodix-oy')}
      </Typography>
      <Typography textAlign='center'>
        Sammonkatu 12, 50130 Mikkeli, Finland. {isMobile && <br />} {t('all-rights-reserved')}
      </Typography>
    </Footer>
    <CookieFAB />
  </>;
};
export default AavistusLandingPageComponent;
