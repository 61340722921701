import { AnimatePresence } from 'framer-motion';
import { Footer } from '../../layout/Footer';
import { HeaderSpacer } from '../../layout/HeaderSpacer';
import { PlanSelectorContainer } from '../../plans/PlanSelector/PlanSelectorContainer';
import { SpacesBloc, useSpaces } from 'bloc/space/SpacesBloc';
import { generatePathUrl } from '../../../utils/urls';
import { handleItemRender } from 'components/cards/cardUtils';
import { useAuth } from '@mindhiveoy/react-auth';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useUserProjects } from '../../../bloc/project/useUserProjects';
import { useUserSettings } from '../../../bloc/useUserSettings';
import Button from '@mui/material/Button';
import CardStrip from '../../cards/CardStrip';
import Form from '../../forms/Form';
import LoadingIndicator from '../../common/LoadingIndicator';
import React, { useCallback, useEffect, useState } from 'react';
import SectionTitle from '../../layout/SectionTitle';
import Typography from '@mui/material/Typography';
import map from 'lodash/map';
import useScreenContext from 'components/layout/useScreenContext';
import type { AuthState } from '@mindhiveoy/react-auth/dist/AuthContext';
import type { CardData } from '../../cards/CardStrip';
import type {
  FeatureId, ModuleId, PathParams, PlanId, SpaceId, UserRoleId
} from '../../../@shared/schema/src';
import type { User } from '@mindhiveoy/schema';

import Backdrop from '@mui/material/Backdrop';
import styled from '@emotion/styled';

export const StyledBackdrop = styled(Backdrop)(({
  theme,
}) => `
  z-index: 10000;
  padding: ${theme.spacing(8)};
`);

/**
 * Single user's home view when signed in. The view will
 * show all things that the user has access to.
 */
export const UserHomeView = () => {
  const userSettings = useUserSettings();

  const [projectCards, setProjectCards,] = useState<CardData[]>([]);
  const userProjects = useUserProjects({} as PathParams);

  const [spaceCards, setSpaceCards,] = useState<CardData[]>([]);
  const [loading, setLoading,] = useState<boolean>(true);
  const [openNewProject, setOpenNewProjectForm,] = useState<boolean>(false);

  const {
    user, authInProgress, // TODO: update @mindhiveoy/react-auth to the correct typings
  } = useAuth() as AuthState<PlanId, ModuleId, FeatureId, UserRoleId, User<PlanId, ModuleId, UserRoleId>> & { authInProgress: boolean; };

  const {
    t,
  } = useTranslation();

  const [openArchive, setOpenArchiveForm,] = useState<boolean>(false);
  const [openDelete, setOpenDeleteForm,] = useState<boolean>(false);

  const router = useRouter();

  const {
    push,
  } = router;

  const {
    screenMode,
  } = useScreenContext();

  const handleArchiveProjectClick = useCallback(() => {
    setOpenArchiveForm(true);
  }, []);

  const handleArchiveProjectClose = useCallback((event: React.MouseEvent) => {
    setOpenArchiveForm(false);
    event.stopPropagation();
  }, []);

  const handleDeleteProjectClose = useCallback((event: React.MouseEvent) => {
    setOpenDeleteForm(false);
    event.stopPropagation();
  }, []);

  const handleNewProjectClose = useCallback((event: React.MouseEvent) => {
    setOpenNewProjectForm(false);
    event.stopPropagation();
  }, []);

  const Archive = useCallback((event: React.MouseEvent) => {
    setOpenArchiveForm(false);
    event.stopPropagation();
  }, []);

  const spacesBloc = useSpaces();

  useEffect(() => {
    const callback = async () => {
      if (!user?.email) {
        return;
      }
      const planId = router.query.planId as string | undefined;
      if (!planId) {
        return;
      }

      // TODO: Find out, can this be done in a better way?
      const spacesBloc = new SpacesBloc();
      const space = await spacesBloc.create(planId as any);
      const path = `/${space._id}/`;
      router.replace(path, path, {
        shallow: true,
      });
    };
    callback();
  }, [router, router.query.planId, user?.email,]);

  // Space setup
  const handleSpaceClick = useCallback((spaceId: SpaceId) => {
    let path = generatePathUrl({
      spaceId,
    }, 'spaceId');
    if (openNewProject) {
      path = `/${path.replace('_', '/')}/_createProject`;
    }
    push(path, path, {
      shallow: true,
    });
  }, [openNewProject, push,]);

  const handleSpaceSettingsClick = useCallback((spaceId: SpaceId) => {
    const path = `${generatePathUrl({
      spaceId,
    }, 'spaceId')}/_settings`;
    push(path, path, {
      shallow: true,
    });
  }, [push,]);

  // const draftElements: BuilderComponentPropsBaseWithId<object>[] = useMemo(() => {
  //   return page?.draftElements ? JSON.parse(page.draftElements) : [];
  // }, [page,]);
  const handleArchiveSpaceClick = useCallback(() => {
    setOpenArchiveForm(true);
  }, []);

  const handleDeleteSpaceClick = useCallback(() => {
    setOpenDeleteForm(true);
  }, []);

  // Project setup
  const handleProjectCardClick = useCallback((projectPath: string) => {
    const path = `/${projectPath.replace('_', '/')}`;
    push(path, path, {
      shallow: true,
    });
  }, [push,]);

  const handleProjectSettingsCardClick = useCallback((projectPath: string) => {
    const path = `/${projectPath.replace('_', '/')}/_settings`;
    push(path, path, {
      shallow: true,
    });
  }, [push,]);

  const handleDeleteProjectClick = useCallback(() => {
    setOpenDeleteForm(true);
  }, []);

  const handleNewProjectClick = useCallback(() => {
    setOpenNewProjectForm(true);
  }, []);

  useEffect(() => {
    if (userProjects) {
      const projects = userProjects ?
        userProjects.map(({
          _id,
          name: title,
          spaceName:
          subTitle,
          media,
        }) => {
          return {
            _id,
            title,
            media,
            subTitle,
          };
        }) :
        [];

      setProjectCards(projects.sort((a, b) => {
        if (!a?.title || !b?.title) {
          return 0;
        }
        return a.title.toLocaleLowerCase().localeCompare(b.title.toLocaleLowerCase());
      }));
    }
    setLoading(false);
  }, [userProjects,]);

  useEffect(() => {
    setLoading(false);
    if (screenMode === 'superuser') {
      return spacesBloc.subscribe((spaces) => {
        const spaceCards: CardData[] = spaces.map((space) => {
          return {
            _id: space._id,
            title: space.name,
            media: space.media,
          };
        });
        setLoading(false);
        setSpaceCards(spaceCards);
      });
    }

    if (!userSettings) {
      setSpaceCards([]);
      return;
    }
    const spaces = userSettings.spaces ?
      map(userSettings.spaces, (space, _id) => {
        return {
          _id,
          title: space.name,
          media: space.media,
        };
      }) :
      [];

    setSpaceCards(spaces.sort((a, b) => {
      if (!a?.title || !b?.title) {
        return 0;
      }
      return a.title.toLocaleLowerCase().localeCompare(b.title.toLocaleLowerCase());
    }));
  }, [screenMode, userSettings, spacesBloc,]);

  if (loading || authInProgress) {
    // show loading if auth is in progress or the data is loading
    return <LoadingIndicator fullScreen />;
  }
  return <>
    <HeaderSpacer />
    {spaceCards?.length > 0 && <>
      <SectionTitle>{t('your-spaces')}</SectionTitle>
      <CardStrip
        mode="plain"
        reflection
        cards={spaceCards}
        onRenderItem={handleItemRender}
        onCardClick={handleSpaceClick}
        onSettingsClick={handleSpaceSettingsClick}
        onArchiveClick={handleArchiveSpaceClick}
        onDeleteClick={handleDeleteSpaceClick} />
    </>}
    {projectCards?.length > 0 && <>
      <SectionTitle>{t('Your projects')}</SectionTitle>
      <CardStrip
        mode="plain"
        reflection
        cards={projectCards}
        onRenderItem={handleItemRender}
        onCardClick={handleProjectCardClick}
        onNewProjectClick={handleNewProjectClick}
        onSettingsClick={handleProjectSettingsCardClick}
        onArchiveClick={handleArchiveProjectClick}
        onDeleteClick={handleDeleteProjectClick} />
    </>}
    <PlanSelectorContainer />
    <Footer />
    <AnimatePresence>
      <StyledBackdrop
        key="new-project-backdrop"
        open={openNewProject}
        onClick={handleNewProjectClose}
      >
        <Form id="new-project">
          <Typography component={'span'}
            variant="h1">{t('Choose the space to create project in')} </Typography>
          <CardStrip
            mode="plain"
            reflection
            cards={spaceCards}
            onCardClick={handleSpaceClick} />
          <Button>{t('Cancel')}</Button>
        </Form>
      </StyledBackdrop>

      <StyledBackdrop
        key="archive-backdrop"
        open={openArchive}
        onClick={handleArchiveProjectClose}
      >
        <Form id="archive">
          <Typography component={'span'}
            variant="h1">Archive project?
          </Typography>
          <Button onClick={Archive}>Archive</Button>
          <Button>{t('Cancel')}</Button>
        </Form>
      </StyledBackdrop>

      <StyledBackdrop
        key="delete-backdrop"
        open={openDelete}
        onClick={handleDeleteProjectClose}
      >
        <Form id="delete">
          <Typography component={'span'}
            variant="h1">Deleting project?
          </Typography>
          <Button onClick={handleArchiveProjectClick}>Archive instead</Button>
          <Button>{t('Delete')}</Button>
          <Button>{t('Cancel')}</Button>
        </Form>
      </StyledBackdrop>
    </AnimatePresence>
  </>;
};
