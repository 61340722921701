import { useAuth } from '@mindhiveoy/react-auth';
import usePathParams from '../../utils/hooks/usePathParams';
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  collection, getFirestore, onSnapshot, orderBy, query, where
} from 'firebase/firestore';
import { firebaseApp } from '../../schema';
import { mapDocument } from '../../utils/firebase/mapDocument';
import { useEffect, useState } from 'react';
import useFirestoreErrorHandler from '../../utils/hooks/useFirestoreErrorHandler';
import type { QuerySnapshot } from 'firebase/firestore';
import type { SpaceParams } from '../../@shared/schema/src';
import type { UserProjectInfo } from '../../@shared/schema/src';
import type { WithId } from '@mindhiveoy/schema';

export const useUserProjects = (params: SpaceParams): WithId<UserProjectInfo>[] => {
  const {
    user,
  } = useAuth();

  const {
    spaceId,
  } = usePathParams(params) as any;

  const onError = useFirestoreErrorHandler();

  const [projects, setProjects,] = useState<WithId<UserProjectInfo>[]>([]);

  useEffect(() => {
    console.debug({
      uid: user?.uid,
      email: user?.email,
      spaceId,
    });
    if (!(user?.uid && user?.email)) {
      setProjects([]);
      return;
    }

    const callback = (snapshot: QuerySnapshot<UserProjectInfo>) => {
      const projects = snapshot.docs.map((doc) => mapDocument<UserProjectInfo>(doc));

      if (spaceId) {
        const newProjects = projects.map((project) => ({
          ...project,
          _id: project._id.substring(project._id.indexOf('_') + 1),
        }));

        setProjects(newProjects);
      } else {
        setProjects(projects);
      }
    };

    const firestore = getFirestore(firebaseApp());

    const projectsRef = collection(
      firestore,
      `users/${user.uid}/usersProjects`
    );

    let unsubscribe: any;
    if (spaceId) {
      unsubscribe = onSnapshot(
        query(
          projectsRef,
          where('spaceId', '==', spaceId),
          orderBy('name')
        ),
        callback as any, // TODO: Fix
        onError
      );
    } else {
      unsubscribe = onSnapshot(projectsRef, callback as any, onError);
    }

    return () => unsubscribe && unsubscribe();
  }, [user?.uid, spaceId, onError, user?.email,]);

  return projects;
};
