import { Emphasis } from './aavistus/components/LandingCard';

/**
 * Return a JSX.Element array with mentions and hashtags as components
 * and the rest as text
 * @param text
 */
export const parseTextEmphasis = (text: string) => {
  const splitText = text.split(' ');
  const result: JSX.Element[] = [];

  splitText.forEach((word, index) => {
    if (word.startsWith('@')) {
      result.push(<Emphasis key={`e_${index}`}>{word} </Emphasis>);
      return;
    }

    if (word.startsWith('#')) {
      result.push(<Emphasis key={`h_${index}`}>{word} </Emphasis>);
      return;
    }
    if (word.startsWith('_')) {
      result.push(<i key={`e_${index}`}><Emphasis>{word.substring(1)} </Emphasis></i>);
      return;
    }
    if (word.startsWith('*')) {
      result.push(<b key={`e_${index}`}><Emphasis>{word.substring(1)} </Emphasis></b>);
      return;
    }
    result.push(<span key={`a_${index}`}>{word}</span>);
    result.push(<span key={`b_${index}`}> </span>);
  });

  return result;
};
