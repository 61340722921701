import styled from '@emotion/styled';

export const Footer = styled.footer(({
  theme,
}) => `
  padding-top: 100px;
  padding-bottom: ${theme.spacing(2)};
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  text-align: center;
`);
