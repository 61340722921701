import { type DocumentSnapshot, doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { FRONTEND_SETTINGS_DOC } from '../@shared/schema/src';
import { firebaseApp } from '../schema';
import { useAuth } from '@mindhiveoy/react-auth';
import { useEffect, useState } from 'react';
import useFirestoreErrorHandler from '../utils/hooks/useFirestoreErrorHandler';
import type { UserFrontendSettings } from '../@shared/schema/src';

/**
 * Return user specific settings. Settings include mainly denormalized data to
 * keep the front fast and save database sessions.
 *
 * @return {UserFrontendSettings | null} Current authenticated user's settings if any.
 */
export const useUserSettings = () => {
  const {
    user,
    authenticated,
  } = useAuth();

  const onError = useFirestoreErrorHandler();

  const [settings, setSettings,] = useState<UserFrontendSettings | null>(null);

  useEffect(() => {
    if (!authenticated) {
      setSettings(null);
      return;
    }

    const onUserSettingSnapshot = (snapshot: DocumentSnapshot) => {
      setSettings(snapshot.exists() ? snapshot.data() as UserFrontendSettings : null);
    };

    let unsubscribe: any = undefined;

    if (user?.uid && user?.email) {
      const firestore = getFirestore(firebaseApp());

      const userSettingsRef = doc(
        firestore,
        'users', user.uid, 'userSettings', FRONTEND_SETTINGS_DOC
      );

      unsubscribe = onSnapshot(
        userSettingsRef,
        onUserSettingSnapshot,
        onError
      );
      return;
    }
    setSettings(null);
    return () => unsubscribe?.();
  }, [authenticated, onError, user?.email, user?.uid,]);

  return settings;
};
