/* eslint-disable max-len */
import { Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'next-i18next';
import styled from '@emotion/styled';
import theme from 'theme';

const Background = styled.div`
  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(12, 5)};
  }
  ${theme.breakpoints.up('xxl')} {
    min-height: 700px;
  }
  width: 100%;
  height: 100dvh;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${theme.spacing(14, 10)};
  background-image: url("/images/landingpage/hero1_cropped.jpg");
  gap: ${theme.spacing(4)};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

const TextArea = styled.div`
  ${theme.breakpoints.down('md')} {
    width: 100%;
    gap: ${theme.spacing(2)};
  }
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(4)};
  justify-content: center;
  align-items: center;
`;

const Title = styled(Typography)`
  ${theme.breakpoints.between('sm', 'lg')} {
      font-size: 4rem;
      line-height: 4.2rem;
  }
  ${theme.breakpoints.down('sm')} {
      font-size: 2.75rem;
      line-height: 2.8rem;
  }
  font-size: 5rem;
  letter-spacing: -0.1rem;
  line-height: 5.1rem;
  font-weight: 600;
  text-align: center;
`;

const LandingHero = () => {
  const {
    t,
  } = useTranslation();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return <Background>
    <TextArea>
      <Title>
        {t('Meet the future of foresight')}
      </Title>
      <Typography variant="h2"
        textAlign={'center'}
        lineHeight={isMobile ? '2.7rem' : '3.0rem'}>
        {t('xdelphi-brings-the-wisdom-of-crowds-at-your-fingertips-etc')}
      </Typography>
    </TextArea>

    {/* <ButtonArea>
      <Button
        withoutMargin
        variant='contained'
        bgcolor="lightGrey">
        {t('Learn more')}
      </Button>
    </ButtonArea> */}
  </Background>;
};
export default LandingHero;
