import { ADMIN_BUNDLE } from '@shared/schema/src/https/bundles';
import { createBundleFunctionFactory } from 'utils/firebase/bundles/createBundleFunctionFactory';
import type {
  BeginPaymentMethodAdditionMessage,
  CreateCustomerMessage, CreateInvoiceMessage, ListInvoicesMessage, SavePaymentCardMessage, UpdateCustomerMessage, UpdateInvoiceMessage
} from '@shared/schema/src/https/payments';
import type { CreateCheckOutSessionMessage } from '@shared/schema/src/https/payments/CreateCheckoutSession';

const factory = createBundleFunctionFactory(ADMIN_BUNDLE);

export const createCustomer = factory<CreateCustomerMessage>(
  'create-customer'
);

export const updateCustomer = factory<UpdateCustomerMessage>(
  'update-customer'
);

export const savePaymentCard = factory<SavePaymentCardMessage>(
  'save-payment-card'
);

export const updateInvoice = factory<UpdateInvoiceMessage>(
  'update-invoice'
);

export const createInvoice = factory<CreateInvoiceMessage>(
  'create-invoice'
);

export const beginPaymentMethodAddition = factory<BeginPaymentMethodAdditionMessage>(
  'begin-payment-method-addition'
);

export const listInvoices = factory<ListInvoicesMessage>(
  'list-invoices'
);

export const createCheckoutSession = factory<CreateCheckOutSessionMessage>(
  'create-checkout-session'
);
