import {
  Box, Drawer, Grid, List, Toolbar, Typography, useMediaQuery
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'next-i18next';
import CloseIcon from '@mui/icons-material/Close';
import InstagramIcon from '@mui/icons-material/Instagram';
import Link from 'next/link';
import MenuIcon from '@mui/icons-material/Menu';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import styled from '@emotion/styled';
import styled$ from 'utils/react/styled$';
import theme from 'theme';

interface LandingNavbarProps {
  bottomNav?: boolean;
}

const StyledAppBar = styled$.div<{
  $bottomNav?: boolean;
}>(({
  theme,
  $bottomNav,
}) => `
  ${theme.breakpoints.down('md')} {
    padding: ${theme.spacing(1, 0)};
  }
  box-shadow: none;
  color: ${theme.palette.primary.contrastText};
  position: ${$bottomNav ? 'relative' : 'absolute'};
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  top: ${$bottomNav ? 'initial' : 0};
  background-image: none;
  padding: ${$bottomNav ? theme.spacing(5, 0) : 0};
  margin: 0;
`);

const StyledToolbar = styled(Toolbar)(({
  theme,
}) => `
  ${theme.breakpoints.down('sm')} {
    flex-direction: column;
    align-items: flex-start;
  }
  align-items: center;
  padding: ${theme.spacing(1, 1)};
`);

const LinkArea = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing(3)};
`;

const StyledLink = styled(Link)`
  ${theme.breakpoints.down('md')} {
    margin: 0;
    padding: 0;
  }
  color: ${theme.palette.primary.contrastText};
  text-decoration: none;
  font-size: ${theme.typography.h6.fontSize};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  text-transform: uppercase;
  :hover {
    text-decoration: underline;
    text-underline-offset: 3px;
    text-decoration-color: ${theme.palette.primary.main};
  }
`;

const Icons = styled.div` 
  ${theme.breakpoints.down('md')} {
    margin: ${theme.spacing(5, 0, 0, 0)};
    justify-content: center;
  }
  display: flex;
  gap: ${theme.spacing(2)};
  margin-left: ${theme.spacing(8)};
`;

const StyledBox = styled(Box)`
  width: 100vw;
  height: 100dvh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(3, 6, 12, 6)};
`;

const MobileUpperNav = styled.div`
  width: 90vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const pages = ['home', 'features', 'pricing',];

const XDelphiLandingNavbar = ({
  bottomNav,
}: LandingNavbarProps) => {
  const [open, setOpen,] = useState(false);

  const {
    t,
  } = useTranslation();

  const isWeb = useMediaQuery(theme.breakpoints.up('xl'));

  const handleDrawerOpen = useCallback(() => {
    setOpen(!open);
  }, [open,]);

  // const {
  //   locale,
  // } = useRouter();

  // const path = locale === 'en' ? '/' : `/${locale}/`;

  return (
    <StyledAppBar $bottomNav={bottomNav}
      id='home'>
      <StyledToolbar>
        {
          isWeb ?
            <>
              {/* <LinkArea>
                {pages.map((page) =>
                  <StyledLink href={`${path}/#${page}`}
                    shallow
                    key={page}>
                    <Typography textAlign="center">{t(page)}</Typography>
                  </StyledLink>
                )} */}

              {
                /*  Bottom navbar social media icons  */
                bottomNav &&
                  <Icons>
                    <Link href={'https://twitter.com/xdelphiai'}
                      target='_blank'>
                      <TwitterIcon />
                    </Link>
                    <Link href={'https://www.instagram.com/xdelphiai/'}
                      target='_blank'>
                      <InstagramIcon />
                    </Link>
                    <Link href={'https://youtube.com/@XDelphiAI'}
                      target='_blank'>
                      <YouTubeIcon />
                    </Link>
                  </Icons>
              }

              {/* </LinkArea> */}
              {/* Upper navbar Sign up -button */}
              {/* {
                !bottomNav && <SigningButtons />
              } */}
            </> :
            /* Upper navbar mobile drawer */
            <>
              <Drawer
                anchor='left'
                open={open}
                onClose={handleDrawerOpen}
              >
                <StyledBox
                  role="presentation"
                >
                  <Grid container
                    justifyContent={'flex-end'}
                    marginBottom={9}>
                    <Grid item>
                      <CloseIcon
                        onClick={handleDrawerOpen}
                        onKeyDown={handleDrawerOpen} />
                    </Grid>
                  </Grid>

                  <List>
                    {pages.map((page) =>
                      <StyledLink href={`#${page}`}
                        shallow
                        key={page}
                        onClick={handleDrawerOpen}
                        onKeyDown={handleDrawerOpen}>
                        <Typography textAlign="center">{t(page)}</Typography>
                      </StyledLink>
                    )}
                  </List>
                </StyledBox>
              </Drawer>
              <LinkArea>
                {
                  bottomNav ?
                    <>
                      <List>
                        {pages.map((page) =>
                          <StyledLink href={`./#${page}`}
                            shallow
                            key={page}>
                            <Typography textAlign="center"
                              width={'90vw'}>{t(page)}</Typography>
                          </StyledLink>
                        )}
                        <Icons>
                          <Link href={'https://twitter.com/xdelphiai'}
                            target='_blank'>
                            <TwitterIcon />
                          </Link>
                          <Link href={'https://www.instagram.com/xdelphiai/'}
                            target='_blank'>
                            <InstagramIcon />
                          </Link>
                          <Link href={'https://youtube.com/@XDelphiAI'}
                            target='_blank'>
                            <YouTubeIcon />
                          </Link>
                        </Icons>
                      </List>
                    </> :
                    <MobileUpperNav>

                      <MenuIcon onClick={handleDrawerOpen} />

                      {/* <SigningButtons /> */}
                    </MobileUpperNav>
                }
              </LinkArea>
            </>
        }
      </StyledToolbar>

    </StyledAppBar>
  );
};
export default XDelphiLandingNavbar;
